<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{ $t('employee.employeesList') }}</span>
        <CommonAdd v-if="accessRight.includes('create')" :name="$t('employee.employee')" pathName="addEmployee" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <EmployeeListTable :listSkeleton.sync="listSkeleton" @redirectOnEditPage="redirectOnEditPage" @deleteItem="deleteItem" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import EmployeeListTable from './EmployeeListTable';
import CommonAdd from '@/components/CommonAdd';

export default {
  name: 'Employee',
  components: {
    EmployeeListTable,
    CommonAdd
  },
  data() {
    return {
      listSkeleton: false
    }
  },
  computed: {
    ...mapGetters([
      'accessRight'
    ]),
  },
  methods: {
    redirectOnEditPage(item) {
      const path = 'employee/editEmployee/' + item.id
      window.open(path, '_blank')
    },
    deleteItem(item) {
      this.$store.dispatch('employee/DeleteEmployeeItemById', item.id);
    }
  }
}
</script>
